








import { Component, Vue, Prop } from "vue-property-decorator";


@Component
export default class MenuItem extends Vue {
    @Prop({ default: "" })
    private icon!: string;
    @Prop({ default: "" })
    private title!: string;
}
