































import { Route } from "vue-router";
import { Component, Vue, Prop } from "vue-property-decorator";

import Item from "./Item.vue";


@Component({
    name: "SidebarItem",
    components: { Item },
})
export default class SidebarItem extends Vue {
    @Prop({ required: true })
    private item!: Route;
    @Prop({ default: false })
    private isNest!: boolean;
    @Prop({ default: "" })
    private basePath!: string;


    private resolvePath(...routePath: string[]) {
        return (this.basePath + routePath.join("\/")).replace("\/\/", "\/");
    }

    private clickLink(routePath: string, e: Event) {
        if (!/^(https?):\/\//.test(routePath)) {
            e.preventDefault();
            this.$router.push(this.resolvePath(routePath));
        }
    }

    private checkPermissions(permissions: string[]) {
        if (!this.$MStore.User.Auth.Permissions || this.$MStore.User.Auth.Permissions.length === 0) return false;

        return this.$MStore.User.Auth.Permissions.includes("full") || permissions.every((p) => this.$MStore.User.Auth.Permissions.includes(p));
    }

    private filterRoutesByPermission(routes: Route[]) {
        return routes.filter((route) => !route.meta.permissions || this.checkPermissions(route.meta.permissions));
    }
}
