var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
    _vm.title
      ? _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }