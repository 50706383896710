var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.meta || !_vm.item.meta.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          !_vm.item.children || _vm.item.children.length === 0
            ? [
                _c(
                  "a",
                  {
                    attrs: { href: _vm.item.path, target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.clickLink(_vm.item.path, $event)
                      },
                    },
                  },
                  [
                    _c(
                      "el-menu-item",
                      { attrs: { index: _vm.resolvePath(_vm.item.path) } },
                      [
                        _vm.item.meta
                          ? _c("item", {
                              attrs: {
                                icon: _vm.item.meta.icon,
                                title: _vm.item.meta.title,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "el-submenu",
                { attrs: { index: _vm.item.name || _vm.item.path } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item.meta
                        ? _c("item", {
                            attrs: {
                              icon: _vm.item.meta.icon,
                              title: _vm.item.meta.title,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.filterRoutesByPermission(_vm.item.children),
                    function (child) {
                      return !child.meta || !child.meta.hidden
                        ? [
                            child.children && child.children.length > 0
                              ? _c("sidebar-item", {
                                  key: _vm.resolvePath(child.path),
                                  staticClass: "nest-menu",
                                  attrs: {
                                    "is-nest": true,
                                    item: child,
                                    "base-path": _vm.resolvePath(child.path),
                                  },
                                })
                              : _c(
                                  "a",
                                  {
                                    key: child.name,
                                    attrs: {
                                      href: child.path,
                                      target: "_blank",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickLink(child.path, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-menu-item",
                                      {
                                        attrs: {
                                          index: _vm.resolvePath(child.path),
                                        },
                                      },
                                      [
                                        child.meta
                                          ? _c("item", {
                                              attrs: {
                                                icon: child.meta.icon,
                                                title: child.meta.title,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        : _vm._e()
                    }
                  ),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }