var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-wrapper" }, [
    _c(
      "div",
      { staticClass: "sidebar-wrapper-top" },
      [
        _c(
          "el-dropdown",
          { staticClass: "user-container", attrs: { trigger: "click" } },
          [
            _c("div", { staticClass: "user-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: _vm.avatar },
              }),
              _c("span", { staticClass: "user-name" }, [
                _vm._v("ADMIN"),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "router-link",
                  { staticClass: "inlineBlock", attrs: { to: "/" } },
                  [_c("el-dropdown-item", [_vm._v("Home")])],
                  1
                ),
                _c("el-dropdown-item", { attrs: { divided: "" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Выйти")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "sidebar-wrapper-middle" },
      [
        _c(
          "el-scrollbar",
          { attrs: { "wrap-class": "scrollbar-wrapper" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "sidebar-vertical-menu",
                attrs: {
                  "show-timeout": 200,
                  "default-active": _vm.$route.path,
                  mode: "vertical",
                  "background-color": "#1C2940",
                  "text-color": "#bfcbd9",
                  "active-text-color": "#409EFF",
                },
              },
              _vm._l(_vm.routes, function (route) {
                return _c("sidebar-item", {
                  key: route.name,
                  attrs: { item: route, "base-path": _vm.rootRoute.path },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }