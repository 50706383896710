





































import { Route } from "vue-router";
import { Component, Vue } from "vue-property-decorator";

import SidebarItem from "./SidebarItem.vue";


@Component({
  components: {
    SidebarItem,
  },
})
export default class SideBar extends Vue {
    private data(): object {
        return {
        };
    }

    private get avatar() {
        return "";
        // return "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80";
        // return UserModule.avatar;
    }

    private get rootRoute() {
        const routes = (this.$router as any).options.routes;
        const homeRoute = (routes) ? routes.find((route: any) => route.name === "home") : undefined;

        return homeRoute || [];
    }

    private get routes() {
        const homeRoute = this.rootRoute;

        return (homeRoute) ? this.filterRoutesByPermission(homeRoute.children) : [];
    }

    private checkPermissions(permissions: string[]) {
        if (!this.$MStore.User.Auth.Permissions || this.$MStore.User.Auth.Permissions.length === 0) return false;

        return this.$MStore.User.Auth.Permissions.includes("full") || permissions.every((p) => this.$MStore.User.Auth.Permissions.includes(p));
    }

    private filterRoutesByPermission(routes: Route[]) {
        return routes.filter((route) => !route.meta.permissions || this.checkPermissions(route.meta.permissions));
    }

    private async logout() {
         this.$router.push({ name: "logout" });
    }
}
