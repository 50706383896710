













import { Vue, Component } from "vue-property-decorator";
import { Sidebar } from "@/components";

@Component({
    components: {
        Sidebar,
    },
})
export default class Main extends Vue {
}
